import React from "react"

import "@cloudflare/cloudflare-brand-assets/css/components/footer.css"
import "./footer.css"
const Footer = () => (
  <footer className="Footer Footer-with-top-separator over-write-paddding">
    <div className="Footer--legal">
      {" "}
      <a
        className="Link Link-without-underline"
        href="https://myweighbridge.com/"
      >
        @Copyright {new Date().getFullYear()} Myweighbridge - Proudly build By
        Odan Infotech LLP.
      </a>
    </div>
  </footer>
)

export default Footer
