import React from "react"
import { Link } from "gatsby"

// import isDOMavailable from "../isDOMavailable.js"

import "@cloudflare/cloudflare-brand-assets/css/components/cloudflare-workers-logo.css"
import "@cloudflare/cloudflare-brand-assets/css/components/button.css"

import "./nav.css"

const Nav = () => {
  return (
    <nav className="Nav">
      <Link className="Nav--link Nav--link-logo" to="/">
        <div className="CloudflareWorkersLogo CloudflareWorkersLogo-horizontal-combination-mark">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "100%",
              with: "50%",
            }}
          >
            <img src="/resources/logo/logo.png" alt="logo" width={90} />
          </div>
        </div>
      </Link>

      <div className="Nav--main-links">
        <div className="Nav--item">
          <a className="Nav--link" href="#home">
            Home
          </a>
        </div>

        <div className="Nav--item">
          <a
            className="Nav--link"
            href="https://myweighbridge.medium.com"
            target={"_blank"}
            rel="noreferrer"
          >
            Blog
          </a>
        </div>

        <div className="Nav--item">
          <a className="Nav--link" href="#about">
            About Us
          </a>
        </div>

        <div className="Nav--item">
          <a className="Nav--link" href="#contact">
            Contact
          </a>
        </div>
      </div>
    </nav>
  )
}

export default Nav
